import React from "react"
import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"
import Event from "@material-ui/icons/Event"

const BlogPreview: React.FC = ({ blog }: any) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.blogContainer}>
      <Grid item>
        <GatsbyLink to={`/blog/${blog.slug}`}>
          <GatsbyImage
            image={blog.previewImage.gatsbyImageData}
            alt={blog.previewImage.description}
            className={classes.blogImage}
          />
        </GatsbyLink>
      </Grid>
      <Grid item className={classes.blogContent}>
        <GatsbyLink
          to={`/blog/${blog.slug}`}
          style={{ textDecoration: "none" }}
        >
          <Typography variant="h2" className={classes.blogTitle}>
            {blog.title}
          </Typography>
          <Typography variant="subtitle1" className={classes.blogSubTitle}>
            <Event
              style={{
                fontSize: "1.25rem",
                verticalAlign: "middle",
                marginBottom: "3px",
                marginRight: "2px",
              }}
            />
            {blog.language === "de"
              ? blog.germanPublishDate
              : blog.englishPublishDate}
          </Typography>
        </GatsbyLink>
        <div
          className={classes.blogDescription}
          dangerouslySetInnerHTML={{
            __html: blog.previewText.childMarkdownRemark.html,
          }}
        />
        <GatsbyLink
          to={`/blog/${blog.slug}`}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.blogLink}
          >
            {blog.language === "de" ? "Weiterlesen" : "Continue reading"}
          </Button>
        </GatsbyLink>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  blogContainer: {
    backgroundColor: "#fff",
    marginTop: "1rem",
    marginBottom: "1rem",
    height: "330px",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  blogContent: {
    minHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 10px 10px 10px",
    },
    [theme.breakpoints.up("md")]: {
      width: "60vw",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  blogImage: {
    height: "330px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "93vw",
      height: "auto",
    },
    [theme.breakpoints.only("md")]: {
      width: "440px",
      heigth: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      width: "440px",
    },
  },
  blogTitle: {
    marginTop: "1rem",
    fontSize: "1.75rem",
    color: theme.palette.text.primary,
  },
  blogSubTitle: {
    color: theme.palette.text.primary,
    marginTop: "0.3rem",
    marginBottom: "0",
    fontSize: "1rem",
  },
  blogDescription: {
    overflow: "hidden",
    textOverflow: "clip ellipsis",
  },
  blogLink: {
    textDecoration: "none",
  },
}))

export default BlogPreview
