import React from "react"

// gatsby
import { graphql } from "gatsby"

// @material-ui
import { makeStyles } from "@material-ui/core/styles"
import MainLayout from "../components/layout/layout"
import SEO from "../components/seo"
import BlogPreview from "../blog/BlogPreview"
import { Container, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  container: {},
  blogSite: {
    [theme.breakpoints.down("sm")]: {
      minHeight: "85vh",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "82vh",
      paddingTop: "30px",
    },
  },
  h1: {
    color: "#0f4c81",
    fontSize: "1.75rem",
    marginTop: "20px",
    textAlign: "center",
    fontWeight: 600,
    marginBottom: "30px",
  },
}))

const Blog = ({ data, location }) => {
  const {
    blogHeaderImage: { fixed: blogHeaderData },
    allContentfulBlogPost: { edges: blogPreview },
  } = data
  // = get(data, "blogHeaderImage", "fixed")
  // const blogPreview = get(data, "allContentfulBlogPost", "edges")
  const classes = useStyles()

  if (blogHeaderData) {
    return (
      <MainLayout dark>
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h1" className={classes.h1}>
            Blog on Internationalization & Translation
          </Typography>

          {blogPreview &&
            blogPreview.map(blogPreviewData => (
              <BlogPreview
                key={blogPreviewData.node.id}
                blog={blogPreviewData.node}
              />
            ))}
        </Container>

        <SEO
          general={{
            language: "de, en",
            contentType: "website",
            path: location.pathname,
            description: data.contentfulPages.seoDescription,
            title: data.contentfulPages.seoTitle,
            image: blogHeaderData.src,
          }}
        />
      </MainLayout>
    )
  } else {
    return null
  }
}

export default Blog

export const pageQuery = graphql`
  query BlogPageQuery {
    blogHeaderImage: imageSharp(original: { src: { regex: "/blogLocal/" } }) {
      fixed(width: 1000, traceSVG: { color: "lightgray" }) {
        ...GatsbyImageSharpFixed_tracedSVG
      }
    }
    contentfulPages(page: { eq: "BlogSimpleen" }) {
      seoTitle
      seoDescription
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: {
        publishingBlog: {
          in: ["SimpleenAndTwteam", "Simpleen", "TwteamAndSimpleen"]
        }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          language
          germanPublishDate: publishDate(formatString: "DD.MM.YYYY")
          englishPublishDate: publishDate(formatString: "YYYY-MM-DD")
          previewText {
            childMarkdownRemark {
              html
            }
          }
          previewImage {
            id
            title
            description
            gatsbyImageData(placeholder: BLURRED, quality: 90)
          }
        }
      }
    }
  }
`
